import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import TableContainer from '../../components/Common/TableContainer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Col, Container, Row, Modal, ModalHeader, ModalBody, Form, Label, Input, FormFeedback } from 'reactstrap';
import Dropzone from 'react-dropzone';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import ReactLoading from 'react-loading';

//Import Breadcrumb
import DeleteModal from '../../components/Common/DeleteModal';
import { Day, Img, Description } from './imageBankListCol';

import {
  getImageBankImages as onGetImageBank,
  addNewImageBank as onAddNewImageBank,
  updateImageBank as onUpdateImageBank,
  deleteImageBank as onDeleteImageBank
} from '../../store/actions';

import { isEmpty } from 'lodash';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

const imageBankList = () => {
  document.title = 'ACUSOLO | Admin & Dashboard';

  const dispatch = useDispatch();
  const [ImageBank, setImageBank] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const filters = ['All', 'Head', 'Arm', 'Shoulder', 'Hand', 'Neck'];
  const [activeFilter, setActiveFilter] = useState('All');

  const handleImageBankClicks = () => {
    setImageBank(null);
    setIsEdit(false);
    toggle();
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      side: (ImageBank && ImageBank.side) || '',
      imageName: (ImageBank && ImageBank.imageName) || '',
      color: (ImageBank && ImageBank.color) || '',
      category: (ImageBank && ImageBank.category) || '',
      imageGroups: (ImageBank && ImageBank.imageGroups) || [{ description: '', images: [] }]
    },
    validationSchema: Yup.object({
      imageName: Yup.string().required('Please Enter Image Name'),
      color: Yup.string().required('Please Select Color'),
      category: Yup.string().required('Please Select Category'),
      side: Yup.string().required('Please Select Side'),
      imageGroups: Yup.array().of(
        Yup.object().shape({
          description: Yup.string().required('Please Enter Description'),
          images: Yup.array().min(1, 'Please upload at least one image').required('Images are required')
        })
      )
    }),
    onSubmit: (values) => {
      const formData = new FormData();

      formData.append('imageName', values.imageName);
      formData.append('color', values.color);
      formData.append('category', values.category);
      formData.append('side', values.side);

      values.imageGroups.forEach((group, index) => {
        formData.append(`imageGroups[${index}][description]`, group.description);
        group.images.forEach((image) => {
          // Check if image is a File object or a string URL
          if (image instanceof File) {
            formData.append(`imageGroups[${index}][images][]`, image);
          } else if (typeof image === 'string') {
            // For existing images, append the URL
            formData.append(`imageGroups[${index}][existingImages][]`, image);
          }
        });
      });

      if (isEdit) {
        dispatch(onUpdateImageBank(formData, ImageBank.id));
      } else {
        dispatch(onAddNewImageBank(formData));
        validation.resetForm();
      }
      toggle();
    }
  });

  const ImageBanklistData = createSelector(
    (state) => state.imageBank,
    (state) => ({
      image_bank: state.imageBank,
      loading: state.loading
    })
  );

  const { image_bank, loading } = useSelector(ImageBanklistData);

  const columns = useMemo(
    () => [
      {
        Header: 'Image',
        accessor: 'images',
        filterable: false,
        disableFilters: true,
        Cell: ({ row }) => {
          const imageGroups = row.original.imageGroups || [];

          const images = imageGroups.flatMap((group) => group.images);

          return (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                gap: '10px',
                width: '210px'
              }}
            >
              {images.map((image, index) => (
                <Img key={index} src={image} alt={`Image ${index}`} />
              ))}
            </div>
          );
        }
      },
      {
        Header: 'Image Name',
        accessor: 'url',
        filterable: false,
        disableFilters: true,
        Cell: (img_bnk) => {
          return (
            <div className="d-flex align-items-center gap-2">
              <span>{img_bnk.row.original.imageName}</span>
            </div>
          );
        }
      },
      {
        Header: 'Category',
        filterable: false,
        disableFilters: true,
        accessor: 'name',
        Cell: (img_bnk) => <p className="text-muted mb-0">{img_bnk.row.original.category}</p>
      },
      {
        Header: 'Color',
        accessor: 'color',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Day value={cellProps.value} />;
        }
      },
      {
        Header: 'Side',
        accessor: 'updatedAt',
        width: '70%',
        filterable: false,
        disableFilters: true,
        Cell: (img_bnk) => {
          return <p className="text-muted mb-0">{img_bnk.row.original.side}</p>;
        }
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: '70%',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Description value={cellProps.row.original.imageGroups[0].description} />;
        }
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-eye font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    const ImageBankData = cellProps.row.original;
                    handleImageBankViewClick(ImageBankData);
                  }}
                ></i>
              </Link>
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    const ImageBankData = cellProps.row.original;
                    handleImageBankClick(ImageBankData);
                  }}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => {
                    const ImageBankData = cellProps.row.original;
                    onClickDelete(ImageBankData);
                  }}
                ></i>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const toggleView = () => {
    setViewModal(!viewModal);
  };
  useEffect(() => {
    if (image_bank && !image_bank.length && !isEmpty(image_bank)) {
      dispatch(onGetImageBank());
      setIsEdit(false);
    }
  }, [dispatch, image_bank]);

  useEffect(() => {
    dispatch(onGetImageBank());
    setIsEdit(false);
  }, []);

  useEffect(() => {
    setImageBank(image_bank);
    setIsEdit(false);
  }, [image_bank]);

  useEffect(() => {
    if (!isEmpty(image_bank) && !!isEdit) {
      setImageBank(image_bank);
      setIsEdit(false);
    }
  }, [image_bank]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleImageBankClick = (arg) => {
    const imgBank = arg;

    // Transform the existing images data to match the form structure
    const transformedImageGroups = imgBank.imageGroups.map((group) => ({
      description: group.description,
      images: group.images.map((img) => img.filePath || img) // Use filePath if available, otherwise use the image URL
    }));

    setImageBank({
      id: imgBank.id,
      imageName: imgBank.imageName,
      color: imgBank.color,
      category: imgBank.category,
      description: imgBank.description,
      side: imgBank.side,
      imageGroups: transformedImageGroups
    });

    setIsEdit(true);
    toggle();
  };

  const handleImageBankViewClick = (arg) => {
    const imgBank = arg;

    setImageBank({
      id: imgBank.id,
      color: imgBank.color,
      description: imgBank.description,
      side: imgBank.side,
      category: imgBank.category,
      imageName: imgBank.imageName,
      imageGroups: imgBank.imageGroups
    });

    setViewModal(true);
    toggleView();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete session
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (imgBank) => {
    setImageBank(imgBank);
    setDeleteModal(true);
  };

  const handleDeleteImageBank = () => {
    dispatch(onDeleteImageBank(ImageBank.id));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleFiles = (files, groupIndex) => {
    const updatedGroups = [...validation.values.imageGroups];
    updatedGroups[groupIndex].images = [...updatedGroups[groupIndex].images, ...files];
    validation.setFieldValue('imageGroups', updatedGroups);
  };

  const deleteFile = (groupIndex, fileIndex) => {
    const updatedGroups = [...validation.values.imageGroups];
    updatedGroups[groupIndex].images.splice(fileIndex, 1);
    validation.setFieldValue('imageGroups', updatedGroups);
  };

  const addImageGroup = () => {
    validation.setFieldValue('imageGroups', [...validation.values.imageGroups, { description: '', images: [] }]);
  };

  const removeImageGroup = (index) => {
    const updatedGroups = [...validation.values.imageGroups];
    updatedGroups.splice(index, 1);
    validation.setFieldValue('imageGroups', updatedGroups);
  };

  const getImagePreviewUrl = (image) => {
    if (image instanceof File) {
      return URL.createObjectURL(image);
    }
    return image; // Return the URL string directly for existing images
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteImageBank}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content" style={{ background: '#F6F6F6' }}>
        <Container fluid>
          <Row>
            <Col>
              <Col lg="12">
                <Row className="align-items-center">
                  <Col>
                    <h5 className="font-size-22" style={{ color: '#060606' }}>
                      Images Bank
                    </h5>
                    <p
                      className="font-size-12"
                      style={{
                        color: '#17191B',
                        fontWeight: 500,
                        marginBottom: '24px'
                      }}
                    >
                      12,500 Image Available
                    </p>
                  </Col>
                </Row>
                <div className="row">
                  <div className="col-sm-6 col-md-6">
                    <h5 className="font-size-22" style={{ color: '#060606' }}>
                      List of Images
                    </h5>
                  </div>

                  <div style={{ display: 'flex' }} className="col-sm-6 col-md-6 justify-content-end">
                    <Link
                      to="#"
                      className="btn btn-primary"
                      onClick={() => {
                        handleImageBankClicks();
                      }}
                    >
                      <i className="bx bx-plus me-1"></i> Upload New Images
                    </Link>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-3 my-4">
                  {filters.map((filter) => (
                    <div
                      key={filter}
                      onClick={() => setActiveFilter(filter)}
                      style={{
                        background: activeFilter === filter ? '#000099' : 'white',
                        border: '1px solid #C8CBD0',
                        padding: '6px 12px',
                        borderRadius: '1000px',
                        color: activeFilter === filter ? 'white' : '#17191B',
                        cursor: 'pointer'
                      }}
                    >
                      {filter}
                    </div>
                  ))}
                </div>
                <Row>
                  <Col xl={12}>
                    <TableContainer
                      columns={columns}
                      data={image_bank}
                      isGlobalFilter={true}
                      isAddSessionList={true}
                      customPageSize={10}
                      className="table align-middle datatable dt-responsive table-check nowrap"
                    />

                    <Modal isOpen={modal} toggle={toggle} size={'xl'}>
                      <ModalHeader toggle={toggle} tag="h4">
                        {!!isEdit ? 'Edit Image' : 'Upload Image'}
                      </ModalHeader>
                      <ModalBody>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <Row>
                            <Col xs={6}>
                              <div className="mb-3">
                                <Label className="form-label">Image Name</Label>
                                <Input
                                  name="imageName"
                                  label="imageName"
                                  type="text"
                                  placeholder="Enter Here"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.imageName || ''}
                                  invalid={validation.touched.imageName && validation.errors.imageName ? true : false}
                                />
                              </div>
                            </Col>
                            <Col xs={6}>
                              <div className="mb-3">
                                <Label className="form-label">Category</Label>
                                <Input
                                  name="category"
                                  label="category"
                                  type="select"
                                  placeholder="Select"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.category || ''}
                                  invalid={validation.touched.category && validation.errors.category ? true : false}
                                >
                                  <option value="">Select Category</option>
                                  <option value="DOS">DOS</option>
                                  <option value="ventre/torse">ventre/torse</option>
                                  <option value="OREILLE-NEZ">OREILLE-NEZ TÊTE</option>
                                  <option value="MAIN">MAIN</option>
                                  <option value="BRAS">BRAS</option>
                                  <option value="EPAULE">EPAULE</option>
                                  <option value="TÊTE/COU">TÊTE/COU</option>
                                  <option value="HANCHES">HANCHES</option>
                                  <option value="CUISSES">CUISSES</option>
                                  <option value="GENOUX">GENOUX</option>
                                  <option value="JAMBES">JAMBES</option>
                                  <option value="PIEDS/ORTEILS">PIEDS/ORTEILS</option>
                                  <option value="8 MERVEILLEUX">8 MERVEILLEUX</option>
                                  <option value="PT CROISEMENT">PT CROISEMENT</option>
                                  <option value="PT RÉUNION">PT RÉUNION</option>
                                  <option value="PT IMPORTANT">PT IMPORTANT</option>
                                  <option value="LUO">LUO</option>
                                  <option value="PT URGENCE (XI)">PT URGENCE (XI)</option>
                                  <option value="HORMONES">HORMONES</option>
                                </Input>
                                {validation.touched.category && validation.errors.category && (
                                  <FormFeedback type="invalid">{validation.errors.category}</FormFeedback>
                                )}
                              </div>
                            </Col>
                            <Col xs={6}>
                              <div className="mb-3">
                                <Label className="form-label">Color</Label>
                                <Input
                                  name="color"
                                  label="color"
                                  type="select"
                                  placeholder="Select"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.color || ''}
                                  invalid={validation.touched.color && validation.errors.color ? true : false}
                                >
                                  <option value="">Select Color</option>
                                  <option value="1">1 color</option>
                                  <option value="2">2 color</option>
                                </Input>
                                {validation.touched.color && validation.errors.color && (
                                  <FormFeedback type="invalid">{validation.errors.color}</FormFeedback>
                                )}
                              </div>
                            </Col>
                            <Col xs={6}>
                              <div className="mb-3">
                                <Label className="form-label">Side</Label>
                                <Input
                                  name="side"
                                  label="side"
                                  type="select"
                                  placeholder="Select"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.side || ''}
                                  invalid={validation.touched.side && validation.errors.side ? true : false}
                                >
                                  <option value="">Select Side</option>
                                  <option value="1">1 side</option>
                                  <option value="2">2 side</option>
                                </Input>
                                {validation.touched.side && validation.errors.side && (
                                  <FormFeedback type="invalid">{validation.errors.side}</FormFeedback>
                                )}
                              </div>
                            </Col>
                          </Row>
                          {validation.values.imageGroups.map((group, groupIndex) => (
                            <Row key={groupIndex}>
                              <Col xs={6} className="d-flex align-items-center justify-content-between">
                                <Label className="form-label">Upload Image {groupIndex + 1}</Label>

                                {groupIndex === 0 && (
                                  <Label className="form-label" onClick={addImageGroup} style={{ cursor: 'pointer' }}>
                                    Add More
                                  </Label>
                                )}
                                {groupIndex > 0 && (
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    onClick={() => removeImageGroup(groupIndex)}
                                  >
                                    Remove Group
                                  </button>
                                )}
                              </Col>
                              <Col xs={6}>
                                <Label className="form-label">Description</Label>
                                <Input
                                  name={`imageGroups[${groupIndex}].description`}
                                  type="text"
                                  placeholder="Enter Description"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={group.description}
                                  invalid={
                                    validation.touched.imageGroups?.[groupIndex]?.description &&
                                    validation.errors.imageGroups?.[groupIndex]?.description
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.imageGroups?.[groupIndex]?.description &&
                                  validation.errors.imageGroups?.[groupIndex]?.description && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.imageGroups[groupIndex].description}
                                    </FormFeedback>
                                  )}
                              </Col>
                              <Col xs={6} className="d-flex gap-2">
                                <Dropzone onDrop={(acceptedFiles) => handleFiles(acceptedFiles, groupIndex)} multiple>
                                  {({ getRootProps, getInputProps }) => (
                                    <div
                                      style={{
                                        width: '95px',
                                        height: '112px'
                                      }}
                                    >
                                      <div style={{ cursor: 'pointer' }} {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div
                                          style={{
                                            border: '1px solid #E1E4E8',
                                            width: '95px',
                                            height: '112px',
                                            borderRadius: '6px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                          }}
                                        >
                                          <span
                                            style={{
                                              background: '#000099',
                                              color: 'white',
                                              borderRadius: '100%',
                                              fontSize: '16px',
                                              height: '24px',
                                              width: '24px',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center'
                                            }}
                                          >
                                            +
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                                <div id="file-previews" className="d-flex gap-2 flex-wrap">
                                  {group.images.map((file, fileIndex) => (
                                    <div
                                      key={fileIndex}
                                      className="dz-processing dz-image-preview dz-success dz-complete"
                                      style={{
                                        width: '95px',
                                        height: '112px',
                                        position: 'relative'
                                      }}
                                    >
                                      <img
                                        data-dz-thumbnail=""
                                        height="100%"
                                        className="avatar-lg rounded bg-light"
                                        alt={file.name || 'Image'}
                                        src={getImagePreviewUrl(file)}
                                        style={{
                                          width: '95px',
                                          height: '112px',
                                          objectFit: 'cover'
                                        }}
                                      />
                                      <button
                                        onClick={() => deleteFile(groupIndex, fileIndex)}
                                        style={{
                                          position: 'absolute',
                                          top: '-5px',
                                          right: '-5px',
                                          background: '#AE0C0C',
                                          color: 'white',
                                          border: 'none',
                                          borderRadius: '50%',
                                          width: '20px',
                                          height: '20px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          cursor: 'pointer'
                                        }}
                                      >
                                        ×
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              </Col>
                            </Row>
                          ))}
                          <Row>
                            <Col>
                              <div className="text-end d-flex justify-content-end gap-2">
                                <button type="button" className="btn btn-danger" onClick={toggle}>
                                  Cancel
                                </button>
                                <button type="submit" className="btn btn-primary save-session">
                                  {!!isEdit ? 'Save' : 'Upload'}
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>
                    <Modal isOpen={viewModal} toggle={toggleView} size={'lg'}>
                      <ModalHeader toggle={toggleView} tag="h4">
                        View Image
                      </ModalHeader>
                      <ModalBody>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex gap-3 align-items-center" style={{ width: '30%' }}>
                            <span style={{ fontWeight: 500 }}>Image Name:</span>
                            {validation.values.imageName}
                          </div>
                          <div className="d-flex gap-3 align-items-center" style={{ width: '30%' }}>
                            <span style={{ fontWeight: 500 }}>Category:</span>
                            {validation.values.category}
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex gap-3 align-items-center" style={{ width: '30%' }}>
                            <span style={{ fontWeight: 500 }}>Color:</span>
                            {validation.values.color}
                          </div>
                          <div className="d-flex gap-3 align-items-center" style={{ width: '30%' }}>
                            <span style={{ fontWeight: 500 }}>Side:</span>
                            {validation.values.side}
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex gap-3 align-items-center" style={{ width: '30%' }}>
                            <span style={{ fontWeight: 500 }}>Description:</span>
                            {validation.values.imageGroups[0].description}
                          </div>
                        </div>
                        <div>
                          {(() => {
                            const imageGroups = validation.values.imageGroups || [];
                            const images = imageGroups.flatMap((group) => group.images);

                            return (
                              <div
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  justifyContent: 'center',
                                  gap: '10px',
                                  width: '100%'
                                }}
                              >
                                {images.map((image, index) => (
                                  <div key={index} style={{ margin: '5px' }}>
                                    <img
                                      style={{
                                        width: '250px',
                                        height: '100%',
                                        objectFit: 'cover',
                                        borderRadius: '8px'
                                      }}
                                      src={image?.filePath}
                                      alt={`Image ${index}`}
                                    />
                                  </div>
                                ))}
                              </div>
                            );
                          })()}
                        </div>
                        <Row>
                          <Col>
                            <div className="text-end d-flex justify-content-end gap-2">
                              <button type="button" className="btn btn-danger" onClick={toggleView}>
                                Cancel
                              </button>
                              <button onClick={toggleView} className="btn btn-primary save-session">
                                Upload
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </ModalBody>
                    </Modal>
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
          <ToastContainer />
          {loading && (
            <div className="loading-container">
              <div className="loading">
                <ReactLoading type="spinningBubbles" color="#007bff" height={100} width={100} />
              </div>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default imageBankList;
