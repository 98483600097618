import axios from 'axios';
import { patch, del, get, post, put, postForTest, patchForUpload } from './api_helper';
import * as url from './url_helper';

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem('user');
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postAuthRegister = (data) => {
  return axios
    .post(url.POST_AUTH_REGISTER, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch((err) => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = 'Sorry! the page you are looking for could not be found';
            break;
          case 500:
            message = 'Sorry! something went wrong, please contact our support team';
            break;
          case 401:
            message = 'Invalid credentials';
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postAuthLogin = (data) => post(url.POST_AUTH_LOGIN, data);

// Edit profile
const postJwtProfile = (data) => post(url.POST_EDIT_JWT_PROFILE, data);

const getProfile = (data) => get(url.GET_EDIT_PROFILE, data);

// const updateProfile = (data) => patch(url.UPDATE_EDIT_PROFILE + data.id, data);

const updateProfile = (data, id) =>
  patchForUpload(url.UPDATE_EDIT_PROFILE + id, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = 'Sorry! the page you are looking for could not be found';
            break;
          case 500:
            message = 'Sorry! something went wrong, please contact our support team';
            break;
          case 401:
            message = 'Invalid credentials';
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postJwtLogin = (data) => post(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
const postJwtForgetPwd = (data) => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = (data) => post(url.SOCIAL_LOGIN, data);

// get contacts
// export const getUsers = () => get(url.GET_USERS)

// // add user
// export const addNewUser = user => post(url.ADD_NEW_USER, user)

// // update user
// export const updateUser = user => put(url.UPDATE_USER, user)

// // delete user
// export const deleteUser = user => del(url.DELETE_USER, { headers: { user } })

// export const getUserProfile = () => get(url.GET_USER_PROFILE)

// ---
// get questions
export const getQuestions = () => get(url.GET_QUESTIONS);

// get questions
export const getQuestionsById = () => get(url.GET_QUESTIONS + question, question);

// add question
export const addNewQuestion = (question) => post(url.ADD_NEW_QUESTION, question);

// update question
export const updateQuestion = (question) => patch(url.UPDATE_QUESTION + question.id, question);

// delete question
export const deleteQuestion = (question) => del(url.DELETE_QUESTION + question, { headers: { question } });

// get question profile
export const getQuestionProfile = () => get(url.GET_QUESTION_PROFILE);

//-----

// ---
// get answers
export const getAnswers = () => get(url.GET_ANSWERS);

// get answers
export const getAnswersById = (id) => get(url.GET_ANSWERS_BY_QID + id, id);

// add answer
export const addNewAnswer = (answer) => post(url.ADD_NEW_ANSWER, answer);

// update answer
export const updateAnswer = (answer) => patch(url.UPDATE_ANSWER + answer.id, answer);

// delete answer
export const deleteAnswer = (answer) => del(url.DELETE_ANSWER + answer, { headers: { answer } });

// get answer profile
export const getAnswerProfile = () => get(url.GET_ANSWER_PROFILE);

//-----

// ---
// get users
export const getUsers = () => get(url.GET_USERS);

// get users
export const getUsersById = () => get(url.GET_USERS + user, user);

// add user
export const addNewUser = (user) => post(url.ADD_NEW_USER, user);

// update user
export const updateUser = (user) => patch(url.UPDATE_USER + user.id, user);

// delete user
export const deleteUser = (user) => del(url.DELETE_USER + user, { headers: { user } });

// get user profile
export const getUserProfile = () => get(url.GET_USER_PROFILE);

//-----

// ---
// get categories
export const getCategories = () => get(url.GET_CATEGORIES);

// get categories
export const getCategoriesById = () => get(url.GET_CATEGORIES + category, category);

// add categories
export const addNewCategory = (category) =>
  postForTest(url.ADD_NEW_CATEGORY, category, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

// update categories
export const updateCategory = (category, id) =>
  patchForUpload(url.UPDATE_CATEGORY + id, category, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

// export const updateCategory = (category) =>
//   patch(url.UPDATE_CATEGORY + category.id, category);

// delete categories
export const deleteCategory = (category) => del(url.DELETE_CATEGORY + category, { headers: { category } });

// get categories profile
export const getCategoryProfile = () => get(url.GET_CATEGORY_PROFILE);
//------

// ---
// get rewards
export const getRewards = () => get(url.GET_REWARDS);

// get rewards
export const getRewardsById = () => get(url.GET_REWARDS + reward, reward);

// add reward
export const addNewReward = (reward) => post(url.ADD_NEW_REWARD, reward);

// update reward
export const updateReward = (reward) => patch(url.UPDATE_REWARD + reward.id, reward);

// delete reward
export const deleteReward = (reward) => del(url.DELETE_REWARD + reward, { headers: { reward } });

// get reward profile
export const getRewardProfile = () => get(url.GET_REWARD_PROFILE);

//-----

// ---
// get 12 point program
export const getPrograms12 = () => get(url.GET_PROGRAMS12);

// get 12 point program
export const getPrograms12ById = () => get(url.GET_PROGRAMS12 + program12, program12);

// add 12 point program
export const addNewProgram12 = (program12) =>
  postForTest(url.ADD_NEW_PROGRAM12, program12, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

// update 12 point program
export const updateProgram12 = (program12, id) =>
  patchForUpload(url.UPDATE_PROGRAM12 + id, program12, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

// delete 12 point program
export const deleteProgram12 = (program12) => del(url.DELETE_PROGRAM12 + program12, { headers: { program12 } });

// get 12 point program  profile
export const getProgram12Profile = () => get(url.GET_PROGRAM12_PROFILE);

//-----

// get 12 Points
export const getPoints12 = () => get(url.GET_POINTS12);

// get 12 Points
export const getPoints12ById = () => get(url.GET_POINTS12 + point12, point12);

// add 12 Points
export const addNewPoint12 = (point12) => post(url.ADD_NEW_POINT12, point12);

// update 12 Points
export const updatePoint12 = (point12, id) =>
  patchForUpload(url.UPDATE_POINT12 + id, point12, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

// delete 12 Points
export const deletePoint12 = (point12) => del(url.DELETE_POINT12 + point12, { headers: { point12 } });

// get 12 Point profile
export const getPoint12Profile = () => get(url.GET_POINT12_PROFILE);

//-----

// ---
// get sessions
export const getSessions = () => get(url.GET_SESSIONS);

// get sessions
export const getSessionsById = (id) => get(url.GET_SESSIONS_BY_QID + id, id);

// get
// add session
export const addNewSession = (session) =>
  postForTest(url.ADD_NEW_SESSION, session, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

// update session
export const updateSession = (session, id) =>
  patchForUpload(url.UPDATE_SESSION + id, session, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

// delete session
export const deleteSession = (session) => del(url.DELETE_SESSION + session, { headers: { session } });

// get session profile
export const getSessionProfile = () => get(url.GET_SESSION_PROFILE);

//-----

// ---
// get 12 pont sessions
export const getSession12s = () => get(url.GET_SESSION12S);

// get 12 pont sessions
export const getSession12sById = (id) => get(url.GET_SESSION12S_BY_QID + id, id);

// add 12 pont session
export const addNewSession12 = (session12) =>
  postForTest(url.ADD_NEW_SESSION12, session12, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

// update 12 pont session
export const updateSession12 = (session12, id) =>
  patchForUpload(url.UPDATE_SESSION12 + id, session12, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

// delete 12 pont session
export const deleteSession12 = (session12) => del(url.DELETE_SESSION12 + session12, { headers: { session12 } });

// get 12 pont session profile
export const getSession12Profile = () => get(url.GET_SESSION12_PROFILE);

//-----

// ---
// get programs
export const getPrograms = () => get(url.GET_PROGRAMS);

// get programs
export const getProgramsById = () => get(url.GET_PROGRAMS + program, program);

export const addNewProgramAnswer = (program) => post(url.ADD_NEW_PROGRAM_ANSWER, program);

// add program
export const addNewProgram = (program) =>
  postForTest(url.ADD_NEW_PROGRAM, program, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

// update program
export const updateProgram = (program) => patch(url.UPDATE_PROGRAM + program.id, program);

// delete program
export const deleteProgram = (program) => del(url.DELETE_PROGRAM + program, { headers: { program } });

// get question program
export const getProgramProfile = () => get(url.GET_PROGRAM_PROFILE);

//-----

// ---
// get devices
export const getDevices = () => get(url.GET_DEVICES);

// get devices
export const getDevicesById = () => get(url.GET_DEVICES + category, category);

// add device
export const addNewDevice = (category) =>
  postForTest(url.ADD_NEW_DEVICE, category, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

// update device
export const updateDevice = (category, id) =>
  patchForUpload(url.UPDATE_DEVICE + id, category, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

// delete device
export const deleteDevice = (category) => del(url.DELETE_DEVICE + category, { headers: { category } });

// get device profile
export const getDeviceProfile = () => get(url.GET_DEVICE_PROFILE);
//------

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA);
export const getYearlyData = () => get(url.GET_YEARLY_DATA);
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA);

export const topSellingData = (month) => get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } });

export const getEarningChartsData = (month) => get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } });

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS);

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId }
  });
};
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId }
  });
};

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText }
  });
};

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText }
  });
};

// get dashboard Market overview data

export const getMarketoverViewData = (periodType) =>
  get(`${url.GET_MARKET_OVERVIEW}/${periodType}`, { params: { periodType } });

export const getWallentData = (data) => get(`${url.GET_WALLENT_BALANCE}/${data}`, { params: { data } });

export const getInvestedData = (data) => get(`${url.GET_Invested_Overview}/${data}`, { params: { data } });

export const getAllImageBankImages = () => get(url.GET_IMAGE_BANK_IMAGES);

export const addNewImageBank = (imageBank) =>
  postForTest(url.ADD_NEW_IMAGE_BANK, imageBank, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
export const updateImageBank = (session, id) =>
  patchForUpload(url.UPDATE_IMAGE_BANK + id, session, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
export const deleteImageBank = (session) => del(url.DELETE_IMAGE_BANK + session, { headers: { session } });

export {
  getLoggedInUser,
  isUserAuthenticated,
  postAuthLogin,
  postAuthRegister,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProfile,
  updateProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment
};
