import {
  ADD_IMAGE_BANK_FAIL,
  ADD_IMAGE_BANK_SUCCESS,
  ADD_NEW_IMAGE_BANK,
  GET_IMAGE_BANK_IMAGES,
  GET_IMAGE_BANK_SUCCESS,
  GET_IMAGE_BANK_FAIL,
  DELETE_IMAGE_BANK,
  DELETE_IMAGE_BANK_SUCCESS,
  DELETE_IMAGE_BANK_FAIL,
  UPDATE_IMAGE_BANK,
  UPDATE_IMAGE_BANK_SUCCESS,
  UPDATE_IMAGE_BANK_FAIL
} from './actionTypes';

export const getImageBankImages = () => ({
  type: GET_IMAGE_BANK_IMAGES
});

export const getImageBankSuccess = (sessions) => ({
  type: GET_IMAGE_BANK_SUCCESS,
  payload: sessions
});

export const getImageBankFail = (error) => ({
  type: GET_IMAGE_BANK_FAIL,
  payload: error
});

export const addNewImageBank = (imageBank) => ({
  type: ADD_NEW_IMAGE_BANK,
  payload: imageBank
});

export const addImageBankSuccess = (imageBank) => ({
  type: ADD_IMAGE_BANK_SUCCESS,
  payload: imageBank
});

export const addImageBankFail = (error) => ({
  type: ADD_IMAGE_BANK_FAIL,
  payload: error
});

export const deleteImageBank = (session) => ({
  type: DELETE_IMAGE_BANK,
  payload: session
});

export const deleteImageBankSuccess = (session) => ({
  type: DELETE_IMAGE_BANK_SUCCESS,
  payload: session
});

export const deleteImageBankFail = (error) => ({
  type: DELETE_IMAGE_BANK_FAIL,
  payload: error
});

export const updateImageBank = (session, id) => ({
  type: UPDATE_IMAGE_BANK,
  payload: { session, id }
});

export const updateImageBankSuccess = (session) => ({
  type: UPDATE_IMAGE_BANK_SUCCESS,
  payload: session
});

export const updateImageBankFail = (error) => ({
  type: UPDATE_IMAGE_BANK_FAIL,
  payload: error
});
