//REGISTER
export const POST_AUTH_REGISTER = '/post-auth-register';

//LOGIN
export const POST_AUTH_LOGIN = '/auth/adminSignin';
export const POST_FAKE_JWT_LOGIN = '/post-jwt-login';
export const POST_FAKE_PASSWORD_FORGET = '/auth-forget-pwd';
export const POST_FAKE_JWT_PASSWORD_FORGET = '/jwt-forget-pwd';
export const SOCIAL_LOGIN = '/social-login';

//PROFILE
export const POST_EDIT_JWT_PROFILE = '/post-jwt-profile';

//TASKS
export const GET_TASKS = '/tasks';

//CONTACTS
// export const GET_USERS = "/users"
// export const GET_USER_PROFILE = "/user"
// export const ADD_NEW_USER = "/add/user"
// export const UPDATE_USER = "/update/user"
// export const DELETE_USER = "/delete/user"

//USERS
export const GET_USERS = '/user/allMinimal';
export const GET_USER_PROFILE = '/user/';
export const ADD_NEW_USER = '/user';
export const UPDATE_USER = '/user/';
export const DELETE_USER = '/user/';

//QUESTIONS
export const GET_QUESTIONS = '/question/all';
export const GET_QUESTION_PROFILE = '/question/';
export const ADD_NEW_QUESTION = '/question';
export const UPDATE_QUESTION = '/question/';
export const DELETE_QUESTION = '/question/';

//12 POINTS
export const GET_POINTS12 = '/point12/all';
export const GET_POINT12_PROFILE = '/point12/';
export const ADD_NEW_POINT12 = '/point12';
export const UPDATE_POINT12 = '/point12/';
export const DELETE_POINT12 = '/point12/';

//SESSIONS
export const GET_SESSIONS = '/session/allMinimal';
export const GET_SESSIONS_BY_QID = '/session/program/';
export const GET_SESSION_PROFILE = '/session/';
export const ADD_NEW_SESSION = '/session';
export const UPDATE_SESSION = '/session/';
export const DELETE_SESSION = '/session/';

//12 POINT SESSIONS
export const GET_SESSION12S = '/session12/allMinimal';
export const GET_SESSION12S_BY_QID = '/session12/program/';
export const GET_SESSION12_PROFILE = '/session12/';
export const ADD_NEW_SESSION12 = '/session12';
export const UPDATE_SESSION12 = '/session12/';
export const DELETE_SESSION12 = '/session12/';

//ANSWERS
export const GET_ANSWERS = '/answer/allMinimal';
export const GET_ANSWERS_BY_QID = '/answer/question/';
export const GET_ANSWER_PROFILE = '/answer/';
export const ADD_NEW_ANSWER = '/answer';
export const UPDATE_ANSWER = '/answer/';
export const DELETE_ANSWER = '/answer/';

//CATEGORIES
export const GET_CATEGORIES = '/category/allMinimal';
export const GET_CATEGORY_PROFILE = '/category/';
export const ADD_NEW_CATEGORY = '/category';
export const UPDATE_CATEGORY = '/category/';
export const DELETE_CATEGORY = '/category/';

//REWARDS
export const GET_REWARDS = '/reward/allMinimal';
export const GET_REWARD_PROFILE = '/reward/';
export const ADD_NEW_REWARD = '/reward';
export const UPDATE_REWARD = '/reward/';
export const DELETE_REWARD = '/reward/';

//REWARDS
export const GET_PROGRAMS12 = '/program12/allMinimal';
export const GET_PROGRAM12_PROFILE = '/program12/';
export const ADD_NEW_PROGRAM12 = '/program12';
export const UPDATE_PROGRAM12 = '/program12/';
export const DELETE_PROGRAM12 = '/program12/';

//PROGRAMS
export const GET_PROGRAMS = '/program/allMinimal';
export const GET_PROGRAM_PROFILE = '/programMedia/';
export const ADD_NEW_PROGRAM = '/programMedia';
export const ADD_NEW_PROGRAM_ANSWER = '/programAnswer';
export const UPDATE_PROGRAM = '/program/';
export const DELETE_PROGRAM = '/programMedia/';

//DEVICES
export const GET_DEVICES = '/device/allMinimal';
export const GET_DEVICE_PROFILE = '/device/';
export const ADD_NEW_DEVICE = '/device';
export const UPDATE_DEVICE = '/device/';
export const DELETE_DEVICE = '/device/';

//dashboard charts data
export const GET_WEEKLY_DATA = '/weekly-data';
export const GET_YEARLY_DATA = '/yearly-data';
export const GET_MONTHLY_DATA = '/monthly-data';

export const GET_EDIT_PROFILE = '/auth/admin/';

export const UPDATE_EDIT_PROFILE = '/auth/admin/';

export const TOP_SELLING_DATA = '/top-selling-data';

export const GET_EARNING_DATA = '/earning-charts-data';

export const GET_PRODUCT_COMMENTS = '/comments-product';

export const ON_LIKNE_COMMENT = '/comments-product-action';

export const ON_ADD_REPLY = '/comments-product-add-reply';

export const ON_ADD_COMMENT = '/comments-product-add-comment';

export const GET_MARKET_OVERVIEW = 'GET_MARKET_OVERVIEW';
export const GET_MARKET_OVERVIEW_SUCCESS = 'GET_MARKET_OVERVIEW_SUCCESS';
export const GET_MARKET_OVERVIEW_FAIL = 'GET_MARKET_OVERVIEW_FAIL';

export const GET_WALLENT_BALANCE = 'GET_WALLENT_BALANCE';

export const GET_WALLENT_BALANCE_ALL_DATA = 'GET_MARKET_ALL_DATA';

export const GET_Invested_Overview = 'GET_Invested_Overview';

//IMAGE_BANK
export const ADD_NEW_IMAGE_BANK = '/image-bank';
export const GET_IMAGE_BANK_IMAGES = '/image-bank/allMinimal';
export const DELETE_IMAGE_BANK = '/image-bank/';
export const UPDATE_IMAGE_BANK = '/image-bank/';
