import {
  GET_SESSIONS_SUCCESS,
  GET_SESSIONS_FAIL,
  ADD_SESSION_SUCCESS,
  ADD_SESSION_FAIL,
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_FAIL,
  DELETE_SESSION_SUCCESS,
  DELETE_SESSION_FAIL,
  GET_SESSION_PROFILE_SUCCESS,
  GET_SESSION_PROFILE_FAIL
} from './actionTypes';

const INIT_STATE = {
  sessions: [],
  sessionProfile: {},
  error: {},
  loading: false
};

const sessions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SESSIONS_SUCCESS:
      return {
        ...state,
        sessions: action.payload
      };

    case GET_SESSIONS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_SESSION_SUCCESS:
      return {
        ...state,
        sessions: [action.payload, ...state.sessions]
      };

    case ADD_SESSION_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_SESSION_PROFILE_SUCCESS:
      return {
        ...state,
        sessionProfile: action.payload
      };

    case UPDATE_SESSION_SUCCESS:
      return {
        ...state,
        sessions: state.sessions.map((session) =>
          session.id.toString() === action.payload.id.toString() ? { session, ...action.payload } : session
        )
      };

    case UPDATE_SESSION_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case DELETE_SESSION_SUCCESS:
      return {
        ...state,
        sessions: state.sessions.filter((session) => session.id.toString() !== action.payload.toString())
      };

    case DELETE_SESSION_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_SESSION_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};

export default sessions;
