import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TableContainer from '../../../components/Common/TableContainer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  Card,
  CardBody,
  CardHeader,
  FormFeedback
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Description, Day, UpdatedAt, CreatedAt, Img } from './sessionlistCol';
import ReactLoading from 'react-loading';
import DeleteModal from '../../../components/Common/DeleteModal';
import {
  getSessions as onGetSessions,
  getSessionsById as onGetSessionsById,
  addNewSession as onAddNewSession,
  updateSession as onUpdateSession,
  deleteSession as onDeleteSession
} from '../../../store/actions';
import { getPrograms as onGetPrograms, getProgramsById as onGetProgramsById } from '../../../store/actions';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

const sessionsList = () => {
  document.title = 'ACUSOLO | Admin & Dashboard';
  const durationnotify = () =>
    toast('Please select the program. ', {
      position: 'top-center',
      hideProgressBar: false,
      className: 'bg-danger text-white'
    });
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/sessions-list/image-bank');
  };

  const [selectedValue, setSelectedValue] = useState('00000000-0000-0000-0000-000000000000');
  const [selectedName, setSelectedName] = useState('All');
  const dispatch = useDispatch();
  const [session, setSession] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    );
    setselectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const onSelectData = (value, name) => {
    setSelectedValue(value);
    setSelectedName(name);
    dispatch(onGetSessionsById(value));
  };

  const [editorData, setEditorData] = useState('');

  const handleSelectChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];

    const value = selectedOption.value;
    const name = selectedOption.text;
    localStorage.setItem('selectedProgramValue', value);
    localStorage.setItem('selectedProgramName', name);

    onSelectData(value, name);
  };
  const storedValue = localStorage.getItem('selectedProgramValue');
  const storedName = localStorage.getItem('selectedProgramName');

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      pid: (session && session.pid) || (storedValue && storedValue) || '',
      pname: (session && session.pname) || (storedName && storedName) || '',
      name: (session && session.name) || '',
      day: (session && session.day) || '',
      description: (session && session.description) || '',
      image: (selectedFiles && selectedFiles[0]) || null
    },
    validationSchema: Yup.object({
      pname: Yup.string().required('Please Enter Program Name'),
      name: Yup.string().required('Please Enter Session Name'),
      description: Yup.string().required('Please Enter Session Description'),
      image: Yup.mixed().required('Please upload an image')
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateSession = {
          id: session.id,
          name: values.name,
          day: values.day,
          description: values.description
        };

        const formData = new FormData();

        Object.keys(updateSession).forEach((key) => {
          formData.append(key, updateSession[key]);
        });

        if (selectedFiles[0]) {
          formData.append('file', selectedFiles[0]);
        }

        dispatch(onUpdateSession(formData, updateSession.id));
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newSession = {
          pid: values['pid'],
          name: values['name'],
          day: values['day'],
          description: values['description']
        };

        const formData = new FormData();

        Object.keys(newSession).forEach((key) => {
          formData.append(key, newSession[key]);
        });

        if (selectedFiles[0]) {
          formData.append('file', selectedFiles[0]);
        }

        dispatch(onAddNewSession(formData));
        validation.resetForm();
      }
      toggle();
      localStorage.removeItem('selectedFiles');
      localStorage.removeItem('selectedProgramValue');
      localStorage.removeItem('selectedProgramName');
    }
  });

  const options = [];

  for (let i = 2; i <= 20; i++) {
    options.push(
      <option key={i} value={i}>
        {i} days
      </option>
    );
  }

  const sessionlistData = createSelector(
    (state) => state.sessions,
    (state) => ({
      sessions: state.sessions,
      loading: state.loading
    })
  );

  const programlistData = createSelector(
    (state) => state.programs,
    (state) => ({
      programs: state.programs
    })
  );

  const { sessions, loading } = useSelector(sessionlistData);
  const { programs } = useSelector(programlistData);

  const columns = useMemo(
    () => [
      {
        Header: 'No',
        Cell: ({ row, state }) => {
          const rowIndex = row.index + 1;
          return <label className="">{rowIndex}</label>;
        }
      },
      {
        Header: 'Img',
        accessor: 'url',
        filterable: false,
        disableFilters: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Img {...cellProps} />;
        }
      },
      {
        Header: 'Session Name',
        filterable: false,
        disableFilters: true,
        accessor: 'name',
        Cell: (session) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {session.row.original.name}
              </Link>
            </h5>
            <p className="text-muted mb-0">{session.row.original.designation}</p>
          </>
        )
      },
      {
        Header: 'Day',
        accessor: 'day',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Day value={cellProps.value} />;
        }
      },
      {
        Header: 'Session Description',
        accessor: 'description',
        width: '70%',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Description value={cellProps.value} />;
        }
      },
      {
        Header: 'CreatedAt',
        accessor: 'createdAt',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CreatedAt {...cellProps} />;
        }
      },

      {
        Header: 'UpdatedAt',
        accessor: 'updatedAt',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <UpdatedAt {...cellProps} />;
        }
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    const sessionData = cellProps.row.original;
                    handleSessionClick(sessionData);
                  }}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => {
                    const sessionData = cellProps.row.original;
                    onClickDelete(sessionData);
                  }}
                ></i>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  useEffect(() => {
    if (programs && !programs.length && !isEmpty(programs)) {
      dispatch(onGetPrograms());
      setIsEdit(false);
    }
  }, [dispatch, programs]);

  useEffect(() => {
    if (sessions && !sessions.length && !isEmpty(sessions)) {
      dispatch(onGetSessions());
      setIsEdit(false);
    }
  }, [dispatch, sessions]);

  useEffect(() => {
    dispatch(onGetSessions());
    setIsEdit(false);
  }, []);

  useEffect(() => {
    dispatch(onGetPrograms());
    setIsEdit(false);
  }, []);

  useEffect(() => {
    setSession(sessions);
    setIsEdit(false);
  }, [sessions]);

  useEffect(() => {
    if (!isEmpty(sessions) && !!isEdit) {
      setSession(sessions);
      setIsEdit(false);
    }
  }, [sessions]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleSessionClick = (arg) => {
    const session = arg;
    setSession({
      id: session.id,
      pname: session.pname,
      name: session.name,
      day: session.day,
      description: session.description
    });

    setIsEdit(true);

    toggle();
  };

  const handleSessionClicks = (result) => {
    setSession({
      pid: result.selectedValue,
      pname: result.selectedName
    });

    setIsEdit(false);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (sessions) => {
    setSession(sessions);
    setDeleteModal(true);
  };

  const handleDeleteSession = () => {
    dispatch(onDeleteSession(session.id));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  useEffect(() => {
    const storedFiles = JSON.parse(localStorage.getItem('selectedFiles'));

    if (storedFiles) {
      const filePaths = storedFiles.map((item) => item.filePath);
      // const filePaths = storedFiles.map((item) => item.images.map((a) => a.filePath)).flat();

      if (storedValue && storedName) {
        setSelectedValue(storedValue);
        setSelectedName(storedName);
        onSelectData(storedValue, storedName);

        dispatch(onGetSessionsById(storedValue));
      }

      setselectedFiles(filePaths);

      toggle();
    } else {
      setselectedFiles([]);
    }
  }, []);

  return (
    <React.Fragment>
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteSession} onCloseClick={() => setDeleteModal(false)} />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <Col lg="12">
                <Row className="align-items-center">
                  <Col>
                    <div className="mb-3">
                      <h5 className="card-title">
                        Sessions List <span className="text-muted fw-normal ms-2">({sessions.length})</span>
                      </h5>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                      <Card>
                        <CardHeader>
                          <p className="text-muted mb-0">
                            Please <code>select</code> program. Before add new session, check selection Program.
                          </p>
                          <br></br>
                          <div className="row">
                            <div className="col-sm-7 col-md-7">
                              <div className="">
                                <div className="ms-auto">
                                  <select className="form-select form-select-md" onChange={handleSelectChange}>
                                    <option value="00000000-0000-0000-0000-000000000000">Select Programs...</option>
                                    {programs.map((program) => (
                                      <option key={program.id} value={program.id}>
                                        {program.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div style={{ display: 'flex' }} className="col-sm-5 col-md-5 justify-content-end">
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={() => {
                                  if (
                                    selectedValue == 'All' ||
                                    selectedValue == '00000000-0000-0000-0000-000000000000'
                                  ) {
                                    durationnotify();
                                  } else {
                                    const result = {
                                      editorData,
                                      selectedName,
                                      selectedValue
                                    };
                                    handleSessionClicks(result);
                                  }
                                }}
                              >
                                <i className="bx bx-plus me-1"></i> Add new
                              </Link>
                            </div>
                          </div>
                        </CardHeader>
                      </Card>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl={12}>
                    <TableContainer
                      columns={columns}
                      data={sessions}
                      isGlobalFilter={true}
                      isAddSessionList={true}
                      customPageSize={10}
                      className="table align-middle datatable dt-responsive table-check nowrap"
                    />

                    <Modal isOpen={modal} toggle={toggle}>
                      <ModalHeader toggle={toggle} tag="h4">
                        {!!isEdit ? 'Edit Session' : 'Add Session'}
                      </ModalHeader>
                      <ModalBody>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <Row>
                            <Col xs={12}>
                              <div className="mb-3">
                                <Label className="form-label">Program</Label>
                                <Input
                                  name="pname"
                                  label="Pname"
                                  type="text"
                                  disabled
                                  placeholder="Insert Program"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.pname || ''}
                                  invalid={validation.touched.pname && validation.errors.pname ? true : false}
                                />
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">Number of Days</Label>
                                <Input
                                  name="day"
                                  label="Day"
                                  type="select"
                                  placeholder="Insert Day"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.day || ''}
                                  invalid={validation.touched.day && validation.errors.day ? true : false}
                                >
                                  <option value="Free">Select Days</option>
                                  <option value="1">1 day</option>
                                  {options}
                                </Input>
                                {validation.touched.day && validation.errors.day ? (
                                  <FormFeedback type="invalid">{validation.errors.day}</FormFeedback>
                                ) : null}
                              </div>

                              <div className="mb-3">
                                <Col className="col-12">
                                  <Label className="form-label">Image</Label>
                                  <Card>
                                    <CardBody>
                                      <Dropzone
                                        onDrop={(acceptedFiles) => {
                                          handleAcceptedFiles(acceptedFiles);
                                        }}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <>
                                            <div className="dropzone" style={{ cursor: 'pointer' }} {...getRootProps()}>
                                              <input {...getInputProps()} />
                                              <div className="dz-message needsclick mt-2">
                                                <div className="mb-1">
                                                  <i className="display-6 text-muted bx bx-cloud-upload" />
                                                  <h6>Drop files here or click to upload.</h6>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              onClick={handleRedirect}
                                              style={{
                                                color: '#000099',
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                                fontWeight: '600',
                                                textAlign: 'center',
                                                marginTop: '10px'
                                              }}
                                            >
                                              select from Image Bank
                                            </div>
                                          </>
                                        )}
                                      </Dropzone>

                                      <div className="dropzone-previews mt-3" id="file-previews">
                                        {selectedFiles.map((f, i) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + '-file'}
                                            >
                                              <div className="p-2">
                                                <Row className="align-items-center col-12">
                                                  <Col xs={12}>
                                                    <Col className="col-auto">
                                                      <img
                                                        data-dz-thumbnail=""
                                                        height="300"
                                                        className="avatar-lg rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview ? f.preview : f}
                                                      />
                                                      <span
                                                        style={{
                                                          width: '100%',
                                                          overflow: 'hidden',
                                                          textOverflow: 'ellipsis',
                                                          whiteSpace: 'nowrap'
                                                        }}
                                                      >
                                                        <Link to="#" className="text-muted font-weight-bold">
                                                          &nbsp;
                                                          {f.name
                                                            ? f.name
                                                            : f.replace(
                                                                'https://acusolo-s3.s3.amazonaws.com/image-bank/',
                                                                ''
                                                              )}
                                                        </Link>

                                                        {/* <strong>
                                                          &nbsp;
                                                          {'(' + f.formattedSize + ')'}
                                                        </strong> */}
                                                      </span>
                                                    </Col>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Card>
                                          );
                                        })}
                                      </div>
                                    </CardBody>
                                    {validation.touched.image && validation.errors.image && !selectedFiles[0] ? (
                                      <FormFeedback type="invalid" className="d-block">
                                        {validation.errors.image}
                                      </FormFeedback>
                                    ) : null}
                                  </Card>
                                </Col>
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">Session Name</Label>
                                <Input
                                  name="name"
                                  type="text"
                                  placeholder="Insert Session Name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.name || ''}
                                  invalid={validation.touched.name && validation.errors.name ? true : false}
                                />
                                {validation.touched.name && validation.errors.name ? (
                                  <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                ) : null}
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">Description</Label>
                                <br></br>
                                <Input
                                  name="description"
                                  label="Description"
                                  type="text"
                                  placeholder="Insert Description"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.description || ''}
                                  invalid={
                                    validation.touched.description && validation.errors.description ? true : false
                                  }
                                />
                                {validation.touched.description && validation.errors.description ? (
                                  <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                ) : null}

                                <Input
                                  name="pid"
                                  label="Pid"
                                  type="text"
                                  placeholder="Insert Program"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.pid || ''}
                                  invalid={validation.touched.pid && validation.errors.pid ? true : false}
                                  style={{ visibility: 'hidden' }}
                                />
                                {validation.touched.pid && validation.errors.pid ? (
                                  <FormFeedback type="invalid">{validation.errors.pid}</FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button type="submit" className="btn btn-primary save-session">
                                  {!!isEdit ? 'Save' : 'Create'}
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
          <ToastContainer />
          {loading && (
            <div className="loading-container">
              <div className="loading">
                <ReactLoading type="spinningBubbles" color="#007bff" height={100} width={100} />
              </div>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default sessionsList;
