import { patch, call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import { ADD_NEW_IMAGE_BANK, DELETE_IMAGE_BANK, GET_IMAGE_BANK_IMAGES, UPDATE_IMAGE_BANK } from './actionTypes';

import {
  addImageBankFail,
  addImageBankSuccess,
  getImageBankSuccess,
  getImageBankFail,
  updateImageBankSuccess,
  updateImageBankFail,
  deleteImageBankFail,
  deleteImageBankSuccess
} from './actions';

//Include Both Helper File with needed methods
import { addNewImageBank, getAllImageBankImages, deleteImageBank, updateImageBank } from '../../helpers/helper';

function* fetchImageBankImages() {
  try {
    const response = yield call(getAllImageBankImages);
    yield put(getImageBankSuccess(response));
  } catch (error) {
    yield put(getImageBankFail(error));
  }
}

function* onAddNewImageBank({ payload: imageBank }) {
  try {
    const response = yield call(addNewImageBank, imageBank);

    yield put(addImageBankSuccess(response));
  } catch (error) {
    yield put(addImageBankFail(error));
  }
}

function* onDeleteImageBank({ payload: session }) {
  try {
    const response = yield call(deleteImageBank, session);
    yield put(deleteImageBankSuccess(response));
  } catch (error) {
    yield put(deleteImageBankFail(error));
  }
}

function* onUpdateImageBank({ payload: { session, id } }) {
  try {
    const response = yield call(updateImageBank, session, id);
    yield put(updateImageBankSuccess(response)); // Ensure the response is in the correct format
  } catch (error) {
    yield put(updateImageBankFail(error));
  }
}

function* imageBankSaga() {
  yield takeEvery(GET_IMAGE_BANK_IMAGES, fetchImageBankImages);
  yield takeEvery(ADD_NEW_IMAGE_BANK, onAddNewImageBank);
  yield takeEvery(DELETE_IMAGE_BANK, onDeleteImageBank);
  yield takeEvery(UPDATE_IMAGE_BANK, onUpdateImageBank);
}

export default imageBankSaga;
