/**
 * add image-bank
 */
export const ADD_NEW_IMAGE_BANK = 'ADD_NEW_IMAGE_BANK';
export const ADD_IMAGE_BANK_SUCCESS = 'ADD_IMAGE_BANK_SUCCESS';
export const ADD_IMAGE_BANK_FAIL = 'ADD_IMAGE_BANK_FAIL';
export const GET_IMAGE_BANK_IMAGES = 'GET_IMAGE_BANK_IMAGES';
export const GET_IMAGE_BANK_SUCCESS = 'GET_IMAGE_BANK_SUCCESS';
export const GET_IMAGE_BANK_FAIL = 'GET_IMAGE_BANK_FAIL';

export const DELETE_IMAGE_BANK = 'DELETE_IMAGE_BANK';
export const DELETE_IMAGE_BANK_SUCCESS = 'DELETE_IMAGE_BANK_SUCCESS';
export const DELETE_IMAGE_BANK_FAIL = 'DELETE_IMAGE_BANK_FAIL';
/*
 * Edit session
 */
export const UPDATE_IMAGE_BANK = 'UPDATE_IMAGE_BANK';
export const UPDATE_IMAGE_BANK_SUCCESS = 'UPDATE_IMAGE_BANK_SUCCESS';
export const UPDATE_IMAGE_BANK_FAIL = 'UPDATE_IMAGE_BANK_FAIL';
