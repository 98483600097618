import React, { useEffect, useState, useRef, useMemo } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Col, Container, Row } from "reactstrap";

import ReactLoading from "react-loading";

import { Day, Img, Description } from "../../ImagesBank/imageBankListCol";
import { useNavigate } from "react-router-dom";

import {
  getImageBankImages as onGetImageBank,
  deleteImageBank as onDeleteImageBank,
} from "../../../store/actions";

import { isEmpty } from "lodash";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

const uploadImageBank = () => {
  document.title = "ACUSOLO | Admin & Dashboard";

  const dispatch = useDispatch();
  const [ImageBank, setImageBank] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const filters = ["All", "Head", "Arm", "Shoulder", "Hand", "Neck"];
  const [activeFilter, setActiveFilter] = useState("All");
  const [selectedImages, setSelectedImages] = useState([]);

  const ImageBanklistData = createSelector(
    (state) => state.imageBank,
    (state) => ({
      image_bank: state.imageBank,
      loading: state.loading,
    })
  );

  const { image_bank, loading } = useSelector(ImageBanklistData);

  const columns = useMemo(
    () => [
      {
        Header: "Image",
        accessor: "images",
        filterable: false,
        disableFilters: true,
        Cell: ({ row }) => {
          const imageGroups = row.original.imageGroups || [];

          const images = imageGroups.flatMap((group) => group.images);

          return (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                gap: "10px",
                width: "210px",
              }}
            >
              {images.map((image, index) => (
                <Img key={index} src={image} alt={`Image ${index}`} />
              ))}
            </div>
          );
        },
      },
      {
        Header: "Image Name",
        accessor: "url",
        filterable: false,
        disableFilters: true,
        Cell: (img_bnk) => {
          return (
            <div className="d-flex align-items-center gap-2">
              <span>{img_bnk.row.original.imageName}</span>
            </div>
          );
        },
      },
      {
        Header: "Category",
        filterable: false,
        disableFilters: true,
        accessor: "name",
        Cell: (img_bnk) => (
          <p className="text-muted mb-0">{img_bnk.row.original.category}</p>
        ),
      },
      {
        Header: "Color",
        accessor: "color",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Day value={cellProps.value} />;
        },
      },
      {
        Header: "Side",
        accessor: "updatedAt",
        width: "70%",
        filterable: false,
        disableFilters: true,
        Cell: (img_bnk) => {
          return <p className="text-muted mb-0">{img_bnk.row.original.side}</p>;
        },
      },
      {
        Header: "Description",
        accessor: "description",
        width: "70%",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Description
              value={cellProps.row.original.imageGroups[0].description}
            />
          );
        },
      },
      {
        Header: "Select",
        Cell: (cellProps) => {
          const ImageBankData = cellProps.row.original;
          const handleCheckboxChange = (e) => {
            if (e.target.checked) {
              setSelectedImages((prev) => [...prev, ImageBankData]);
            } else {
              setSelectedImages((prev) =>
                prev.filter((img) => img.id !== ImageBankData.id)
              );
            }
          };
          return (
            <div className="d-flex align-items-center gap-3">
              <label
                style={{
                  display: "block",
                  position: "relative",
                  paddingLeft: "35px",
                  marginBottom: "12px",
                  cursor: "pointer",
                  fontSize: "16px",
                  userSelect: "none",
                }}
              >
                <input
                  type="checkbox"
                  style={{
                    position: "absolute",
                    opacity: 0,
                    cursor: "pointer",
                    height: 0,
                    width: 0,
                  }}
                  onChange={handleCheckboxChange}
                />
                <span
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "36px",
                    width: "36px",
                    backgroundColor: "transparent",
                    border: "3px solid #D9D9D9",
                    borderRadius: "2px",
                  }}
                  className="checkmark"
                ></span>
                <style>
                  {`
      
        input:checked ~ .checkmark {
          border: 3px solid #000099 !important;
          background-color: #000099 !important;
        }
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }
        input:checked ~ .checkmark:after {
          display: block;
        }
        .checkmark:after {
          left: 12px;
          top: 7px;
          width: 7px;
          height: 13px;
          border: solid white;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
        }
      `}
                </style>
              </label>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (image_bank && !image_bank.length && !isEmpty(image_bank)) {
      dispatch(onGetImageBank());
      setIsEdit(false);
    }
  }, [dispatch, image_bank]);

  useEffect(() => {
    dispatch(onGetImageBank());
    setIsEdit(false);
  }, []);

  useEffect(() => {
    setImageBank(image_bank);
    setIsEdit(false);
  }, [image_bank]);

  useEffect(() => {
    if (!isEmpty(image_bank) && !!isEdit) {
      setImageBank(image_bank);
      setIsEdit(false);
    }
  }, [image_bank]);

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };
  const handleUpload = () => {
    // console.log(selectedImages[0].imageGroups);

    localStorage.setItem(
      "selectedFiles",
      JSON.stringify(selectedImages?.[0]?.imageGroups?.[0]?.images)
    );
    navigate("/sessions-list");
  };
  return (
    <React.Fragment>
      <div className="page-content" style={{ background: "#F6F6F6" }}>
        <Container fluid>
          <Row>
            <Col>
              <Col lg="12">
                <Row className="align-items-center">
                  <Col>
                    <h5 className="font-size-22" style={{ color: "#060606" }}>
                      Images Bank
                    </h5>
                    <p
                      className="font-size-12"
                      style={{
                        color: "#17191B",
                        fontWeight: 500,
                        marginBottom: "24px",
                      }}
                    >
                      12,500 Image Available
                    </p>
                  </Col>
                </Row>
                <div className="row">
                  <div className="col-sm-6 col-md-6">
                    <h5 className="font-size-22" style={{ color: "#060606" }}>
                      List of Images
                    </h5>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-3 my-4">
                  {filters.map((filter) => (
                    <div
                      key={filter}
                      onClick={() => setActiveFilter(filter)}
                      style={{
                        background:
                          activeFilter === filter ? "#000099" : "white",
                        border: "1px solid #C8CBD0",
                        padding: "6px 12px",
                        borderRadius: "1000px",
                        color: activeFilter === filter ? "white" : "#17191B",
                        cursor: "pointer",
                      }}
                    >
                      {filter}
                    </div>
                  ))}
                </div>
                <Row>
                  <Col xl={12}>
                    <TableContainer
                      columns={columns}
                      data={image_bank}
                      isGlobalFilter={true}
                      isAddSessionList={true}
                      customPageSize={10}
                      className="table align-middle datatable dt-responsive table-check nowrap"
                    />
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                className="text-end d-flex justify-content-end gap-2"
                style={{ position: "sticky", bottom: "0", margin: "10px 0" }}
              >
                <button type="button" className="btn btn-danger">
                  Cancel
                </button>
                <button
                  className="btn btn-primary save-session"
                  onClick={handleUpload}
                >
                  Upload Selected Image
                </button>
              </div>
            </Col>
          </Row>
          <ToastContainer />
          {loading && (
            <div className="loading-container">
              <div className="loading">
                <ReactLoading
                  type="spinningBubbles"
                  color="#007bff"
                  height={100}
                  width={100}
                />
              </div>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default uploadImageBank;
