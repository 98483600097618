import {
  GET_SESSION_PROFILE,
  GET_SESSION_PROFILE_FAIL,
  GET_SESSION_PROFILE_SUCCESS,
  GET_SESSIONS,
  GET_SESSIONS_BY_ID,
  GET_SESSIONS_FAIL,
  GET_SESSIONS_SUCCESS,
  ADD_NEW_SESSION,
  ADD_SESSION_SUCCESS,
  ADD_SESSION_FAIL,
  UPDATE_SESSION,
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_FAIL,
  DELETE_SESSION,
  DELETE_SESSION_SUCCESS,
  DELETE_SESSION_FAIL
} from './actionTypes';

export const getSessions = () => ({
  type: GET_SESSIONS
});

export const getSessionsById = (session) => ({
  type: GET_SESSIONS_BY_ID,
  payload: session
});

export const getSessionsSuccess = (sessions) => ({
  type: GET_SESSIONS_SUCCESS,
  payload: sessions
});

export const addNewSession = (session) => ({
  type: ADD_NEW_SESSION,
  payload: session
});

export const addSessionSuccess = (session) => ({
  type: ADD_SESSION_SUCCESS,
  payload: session
});

export const addSessionFail = (error) => ({
  type: ADD_SESSION_FAIL,
  payload: error
});

export const getSessionsFail = (error) => ({
  type: GET_SESSIONS_FAIL,
  payload: error
});

export const getSessionProfile = () => ({
  type: GET_SESSION_PROFILE
});

export const getSessionProfileSuccess = (sessionProfile) => ({
  type: GET_SESSION_PROFILE_SUCCESS,
  payload: sessionProfile
});

export const getSessionProfileFail = (error) => ({
  type: GET_SESSION_PROFILE_FAIL,
  payload: error
});

export const updateSession = (session, sid) => ({
  type: UPDATE_SESSION,
  payload: session
});

export const updateSessionSuccess = (session) => ({
  type: UPDATE_SESSION_SUCCESS,
  payload: session
});

export const updateSessionFail = (error) => ({
  type: UPDATE_SESSION_FAIL,
  payload: error
});

export const deleteSession = (session) => ({
  type: DELETE_SESSION,
  payload: session
});

export const deleteSessionSuccess = (session) => ({
  type: DELETE_SESSION_SUCCESS,
  payload: session
});

export const deleteSessionFail = (error) => ({
  type: DELETE_SESSION_FAIL,
  payload: error
});
