import {
  ADD_IMAGE_BANK_FAIL,
  ADD_IMAGE_BANK_SUCCESS,
  DELETE_IMAGE_BANK,
  DELETE_IMAGE_BANK_FAIL,
  DELETE_IMAGE_BANK_SUCCESS,
  GET_IMAGE_BANK_FAIL,
  GET_IMAGE_BANK_SUCCESS,
  UPDATE_IMAGE_BANK_FAIL,
  UPDATE_IMAGE_BANK_SUCCESS
} from './actionTypes';

const INIT_STATE = {
  imageBank: [],
  error: {},
  loading: false
};

const imageBank = (state = INIT_STATE, action) => {
  console.log("action -->", action)
  switch (action.type) {
    case GET_IMAGE_BANK_SUCCESS:
      return {
        ...state,
        imageBank: action.payload
      };

    case GET_IMAGE_BANK_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_IMAGE_BANK_SUCCESS:
      return {
        ...state,
        imageBank: [action.payload, ...state.imageBank]
      };
    case ADD_IMAGE_BANK_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case DELETE_IMAGE_BANK:
      return {
        ...state,
        imageBank: state.imageBank.filter((bank) => bank.id.toString() !== action.payload.toString())
      };

    case DELETE_IMAGE_BANK_SUCCESS:
      return {
        ...state,
        error: action.payload
      };

    case DELETE_IMAGE_BANK_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case UPDATE_IMAGE_BANK_SUCCESS:
      return {
        ...state,
        imageBank: state.imageBank.map((session) =>
          session.id.toString() === action.payload.id.toString() ? { session, ...action.payload } : session
        )
      };

    case UPDATE_IMAGE_BANK_FAIL:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export default imageBank;
