import React from 'react';
import { Link } from 'react-router-dom';
import { size, map } from 'lodash';
import parse from 'html-react-parser';

const Img = (cell) => {
  return (
    <React.Fragment>
      {!cell.src ? (
        <div className="avatar-lg d-inline-block align-middle me-2">
          <div className="avatar-title bg-light-subtle text-light font-size-24 m-0">
            <i className="bx bxs-program-circle"></i>
          </div>
        </div>
      ) : (
        // <div>
        <img
          className=""
          style={{
            maxHeight: '100px',
            minHeight: '100px',
            height: '100px',
            width: '100px',
            objectFit: 'cover'
          }}
          src={cell.src.filePath}
          alt=""
        />
        // </div>
      )}
    </React.Fragment>
  );
};

const Day = (cell) => {
  return cell.value ? cell.value : '';
};

const Email = (cell) => {
  return cell.value ? cell.value : '';
};

const Description = ({ value }) => {
  return value ? parse(value) : '';
};

const Name = (cell) => {
  return cell.value ? cell.value : '';
};

const Qid = (cell) => {
  return cell.value ? cell.value : '';
};

const CreatedAt = (cell) => {
  // Check if cell value exists
  if (!cell.value) {
    return '';
  }

  // Create a new Date object
  const date = new Date(cell.value);

  // Format the date using Intl.DateTimeFormat
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'UTC' // Ensure consistent timezone handling
  }).format(date);

  return <span>{formattedDate}</span>;
};

const UpdatedAt = (cell) => {
  // Check if cell value exists
  if (!cell.value) {
    return '';
  }

  // Create a new Date object
  const date = new Date(cell.value);

  // Format the date using Intl.DateTimeFormat
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'UTC' // Ensure consistent timezone handling
  }).format(date);

  return <span>{formattedDate}</span>;
};

const Tags = (cell) => {
  return (
    <React.Fragment>
      {map(
        cell.value,
        (tag, index) =>
          index < 2 && (
            <Link
              to="#"
              className="badge bg-primary-subtle text-primary font-size-11 m-1"
              key={'_skill_' + cell.id + index}
            >
              {tag}
            </Link>
          )
      )}
      {size(cell.value) > 2 && (
        <Link to="#" className="badge bg-primary-subtle text-primary font-size-11 m-1" key={'_skill_' + cell.id}>
          {size(cell.value) - 1} + more
        </Link>
      )}
    </React.Fragment>
  );
};

const Projects = (cell) => {
  return cell.value ? cell.value : '';
};

export { Img, Day, Name, Qid, Email, Tags, CreatedAt, UpdatedAt, Description, Projects };
